import React from 'react'
import SbEditable from 'storyblok-react'
import StoryblokClient from 'storyblok-js-client'
import { ics } from 'calendar-link'
import isNode from 'is-node'
import Components from 'src/storyblok/Components'

const { resolveRelations } = require('src/config.js')

const getQueryParam = (param: string): string => {
  if (!isNode) {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get(param) as string
  } else {
    return ''
  }
}

const getParam = function (val: any) {
  let result = ''
  let tmp = []

  window.location.search
    .substr(1)
    .split('&')
    .forEach(function (item) {
      tmp = item.split('=')
      if (tmp[0] === val) {
        result = decodeURIComponent(tmp[1])
      }
    })

  return result
}

class StoryblokEntry extends React.Component {
  storyblok: any

  constructor(props: any) {
    super(props)
    this.state = { story: null, header: { content: {} } }
  }

  loadStoryblokBridge = function (cb: any) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${getQueryParam(
      '_skey'
    )}`
    script.onload = cb
    console.log(2)
    document.getElementsByTagName('head')[0].appendChild(script)
  }

  componentDidMount() {
    this.storyblok = new StoryblokClient({
      accessToken: getQueryParam('_skey'),
      cache: {
        clear: 'auto',
        type: 'memory',
      },
    })
    console.log(1)
    this.loadStoryblokBridge(() => {
      this.initStoryblokEvents()
    })
  }

  loadAllRefs(story: any) {
    if (story) {
      if (story.content) {
        if (
          story?.content?.news_list ||
          story?.content?.content ||
          story?.content?.component === 'page_news_landing' ||
          story?.content?.component === 'page_press'
        ) {
          this.loadNews(story)
        }
        if (
          story?.content?.event_list ||
          story?.content?.content ||
          story?.content?.component === 'page_event_landing'
        ) {
          this.loadEvents(story)
        }
      }

      this.setState({ story: story })
      // this.loadHeader(story.lang)
    }
  }

  loadStory() {
    this.storyblok
      .get(`cdn/stories/${getParam('path')}`, {
        version: 'draft',
        resolve_relations: resolveRelations.join(','),
      })
      .then((response: any) => {
        const { data } = response
        const story = data?.story
        this.loadAllRefs(story)
      })
  }

  loadNews(story) {
    this.storyblok
      .get('cdn/stories', {
        by_slugs: 'aktuellt/*',
        is_startpage: 0,
        sort_by: 'published_at',
      })
      .then((response: any) => {
        if (response && response.data && response.data.stories) {
          const news = response.data.stories.map((post: any) => {
            const { name: title, created_at: date, full_slug: url, id } = post
            return {
              date,
              id,
              title,
              url,
            }
          })

          // Set news to news list
          if (story.content.news_list) {
            if (story.content.news_list.length > 0) {
              const hasLink = story.content.news_list[0].link
              story.content.news_list[0]['posts'] = news.slice(
                0,
                hasLink ? 2 : news.length
              )
              story.content.news_list[0]['posts'] = news
            } else {
              story.content.news_list = []
            }
          }

          // Set news to news landing page
          if (story.content.component === 'page_news_landing') {
            story.content['posts'] = news
          }

          // Set news to press page
          if (story.content.component === 'page_press') {
            story.content['posts'] = news.slice(0, 5)
          }

          // Set news to triple posts module
          if (
            story?.content?.content &&
            Array.isArray(story.content.content) &&
            story.content.content.length > 0
          ) {
            story.content.content.map((blok) => {
              if (blok.component === 'triple_posts') {
                blok['news_list'] = { posts: news.slice(0, 4) }
              }
            })
          }

          this.setState({ story: story })
        }
      })
  }

  loadEvents(story: any) {
    this.storyblok
      .get('cdn/stories', {
        by_slugs: 'kalendarium/*',
        is_startpage: 0,
        sort_by: 'content.date:desc',
      })
      .then((response: any) => {
        if (response && response.data && response.data.stories) {
          let events = response.data.stories.map((post: any) => {
            const {
              name: title,
              content: { date, related_investment_fund },
              id,
            } = post
            const calendarEvent = {
              title: title,
              description: '',
              location: '',
              url: '',
              start: date,
              duration: [1, 'hour'],
            }
            const url = ics(calendarEvent)
            return {
              date,
              id,
              title,
              url,
              related_investment_fund,
            }
          })

          // Set events to event list
          if (story.content.event_list) {
            if (story.content.event_list.length > 0) {
              // Filter by investment fund
              if (story.content.component === 'page_investment_fund') {
                events = events.filter((post: any) => {
                  return post.related_investment_fund === story.uuid
                })
              }
              const hasLink = story.content.event_list[0].link
              story.content.event_list[0]['posts'] = events.slice(
                0,
                hasLink ? 4 : events.length
              ) // Send 4 latest events if dates have passed on client load
            } else {
              story.content.event_list = []
            }
          }

          // Set events to event landing page
          if (story.content.component === 'page_event_landing') {
            story.content['posts'] = events
          }

          // Set news to triple posts module
          if (
            story?.content?.content &&
            Array.isArray(story.content.content) &&
            story.content.content.length > 0
          ) {
            story.content.content.map((blok) => {
              if (blok.component === 'triple_posts') {
                blok['event_list'] = { posts: events.slice(0, 4) }
              }
            })
          }

          this.setState({ story: story })
        }
      })
  }

  loadReferences(story: any) {
    if (story?.content?.list_items?.length > 0) {
      this.storyblok
        .get('cdn/stories', {
          by_uuids: story.content.list_items.join(','),
        })
        .then((response: any) => {
          if (response && response.data && response.data.stories) {
            story.content.list_items = response.data.stories
          }
          this.setState({ story: story })
        })
    }
    if (story?.content?.real_estates_included?.length > 0) {
      this.storyblok
        .get('cdn/stories', {
          by_uuids: story.content.real_estates_included.join(','),
        })
        .then((response: any) => {
          if (response && response.data && response.data.stories) {
            story.content.real_estates_included = response.data.stories
          }
          this.setState({ story: story })
        })
    }
  }

  loadHeader(lang: string) {
    const language = lang === 'default' ? '' : lang + '/'
    window.storyblok.get(
      {
        slug: `${language}global-navi`,
        version: 'draft',
      },
      (data: any) => {
        this.setState({ header: data.story })
      }
    )
  }

  loadFooter(lang: string) {
    const language = lang === 'default' ? '' : lang + '/'
    window.storyblok.get(
      {
        slug: `${language}global-navi`,
        version: 'draft',
      },
      (data: any) => {
        this.setState({ header: data.story })
      }
    )
  }

  initStoryblokEvents() {
    console.log(3)
    this.loadStory()
    console.log(4)

    const sb = window.storyblok
    console.log(5)
    sb.on(['change', 'published'], (payload) => {
      console.log(6)
      this.loadStory()
    })

    sb.on('input', (payload: any) => {
      console.log(6, payload, this.state)
      if (this.state.story && payload.story.id === this.state.story.id) {
        payload.story.content = sb.addComments(
          payload.story.content,
          payload.story.id
        )

        sb.resolveRelations(payload.story, resolveRelations, () => {
          const story = payload.story
          const preFetchedContent = this.state.story.content
          story.content = { ...preFetchedContent, ...story.content }

          this.loadAllRefs(story)
        })

        const story = payload.story
        this.loadAllRefs(story)
      }
    })

    sb.pingEditor(() => {
      if (sb.inEditor) {
        sb.enterEditmode()
      }
    })
  }

  render() {
    if (this.state.story == null) {
      return <div />
    }

    const story = this.state.story
    let content = story.content
    if (content.component === 'header') {
      content = this.state.story
    }

    return (
      <SbEditable content={content}>
        {React.createElement(Components(story.content.component), {
          storyID: story.uuid,
          tags: story.tag_list,
          name: story.name,
          // article: article,
          slug: story.slug,
          isStartPage: story.is_startpage,
          full_slug: story.full_slug,
          date: story.published_at,
          blok: content,
          preview: true,
          // footer: footer,
        })}
      </SbEditable>
    )
  }
}

export default StoryblokEntry
